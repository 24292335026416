import styles from "./ServiceContainer.module.css";
import { Player } from "@lottiefiles/react-lottie-player";
import InView from "react-intersection-observer";
import { createRef } from "react";

interface ServiceContainerProps {
  title: string;
  info: string;
  animation: any;
  textFirst: boolean;
}

export default function ServiceContainer(props: ServiceContainerProps) {
  const player = createRef<Player>();

  return (
    <div
      className={
        styles.container +
        (props.textFirst ? "" : " " + styles.container_reverse)
      }
    >
      <div
        className={
          styles.info_container +
          (props.textFirst ? "" : " " + styles.info_container_reverse)
        }
      >
        <h1 className={styles.title}>{props.title}</h1>
        <p className={styles.info}>{props.info}</p>
      </div>
      <InView
        onChange={(inView) =>
          inView ? player.current?.play() : player.current?.stop()
        }
      >
        <Player
          ref={player}
          src={props.animation}
          keepLastFrame={true}
        ></Player>
      </InView>
    </div>
  );
}
