import ServiceContainer from "./ServiceContainer";
import styles from "./Services.module.css";

import salonAnimation from "../../assets/lottie/salon.json";
import healthCareAnimation from "../../assets/lottie/healthcare.json";
// import placesAnimation from "../../assets/lottie/places.json";
import Features from "../Features/Features";

// Salon features
import { ReactComponent as DesireableTimeSlots } from "../../assets/features/desireable_time_slots.svg";
import { ReactComponent as QuickFastPayment } from "../../assets/features/quick_fast_payment.svg";
import { ReactComponent as LiveQueueStatus } from "../../assets/features/live_queue_status.svg";

// Healthcare features
import { ReactComponent as VerifiedDoctors } from "../../assets/features/verified_doctors.svg";
import { ReactComponent as OnlineConsultancy } from "../../assets/features/online_consultancy.svg";
import { ReactComponent as TopDoctorsNearYou } from "../../assets/features/top_doctors_near_you.svg";

// Place features
// import { ReactComponent as PopularPlaces } from "../../assets/features/popular_places.svg";
// import { ReactComponent as Passes } from "../../assets/features/passes.svg";
// import { ReactComponent as ExploreNoWaitings } from "../../assets/features/explore_no_waitings.svg";

export function Services() {
  return (
    <div id="services" className={styles.services}>
      <ServiceContainer
        title="Salon"
        info="The salon scheduling system helps the user to check the live queue status of the salon with an appointment
          booking feature."
        animation={salonAnimation}
        textFirst={true}
      />
      <Features
        features={[
          {
            image: DesireableTimeSlots,
            feature_name: "Desireable Time Slots",
          },
          {
            image: QuickFastPayment,
            feature_name: "Quick & Fast Payments",
          },
          {
            image: LiveQueueStatus,
            feature_name: "Live Queue Status",
          },
        ]}
      />
      <ServiceContainer
        title="HealthCare"
        info="Health service provides consultancy and an appointment system for the patient to have the check-ups on time."
        animation={healthCareAnimation}
        textFirst={false}
      />
      <Features
        features={[
          {
            image: VerifiedDoctors,
            feature_name: "Verified Doctors",
          },
          {
            image: OnlineConsultancy,
            feature_name: "Online Consultancy",
          },
          {
            image: TopDoctorsNearYou,
            feature_name: "Top Doctor Near You",
          },
        ]}
      />
      {/* <ServiceContainer
        title="Places"
        info="Get tickets early and explore monument places with your family and friends."
        animation={placesAnimation}
        textFirst={false}
      /> */}
      {/* <Features
        features={[
          {
            image: PopularPlaces,
            feature_name: "Popular Places of City",
          },
          {
            image: Passes,
            feature_name: "Fast & Easy Booking of Passes",
          },
          {
            image: ExploreNoWaitings,
            feature_name: "Explore Place with No Waiting",
          },
        ]}
      /> */}
    </div>
  );
}
