import styles from "./FeatureCard.module.css";

export interface Feature {
  image?: any;
  feature_name: string;
}

export default function FeatureCard(feature: Feature) {
  return (
    <div className={styles.card}>
      <feature.image className={styles.image} />
      <h1>{feature.feature_name}</h1>
    </div>
  );
}
