import { ReactComponent as Logo } from "../../assets/ngq.svg";
import styles from "./Navbar.module.css";

export function NavBar() {
  return (
    <header>
      <nav className={styles.nav}>
        <a href="#home">
          <Logo className={styles.logo} />
        </a>
        <ul className={styles.nav_list}>
          <li className={styles.nav_item}>
            <a href="#home">Home</a>
          </li>
          <li className={styles.nav_item}>
            <a href="#services">Services</a>
          </li>
          {/* <li className={styles.nav_item}>
            <a href="/">Blogs</a>
          </li>
          <li className={styles.nav_item}>
            <a href="#join-us">Join Us</a>
          </li> */}
          <li className={styles.nav_item}>
            <a href="#about">About</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
