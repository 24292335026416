import styles from "./Footer.module.css";

import { ReactComponent as CREWLogo } from "../../assets/crew.svg";
import { ReactComponent as NGQLogo } from "../../assets/ngq.svg";
// import { ReactComponent as FacebookLogo } from "../../assets/facebook.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/linkedin.svg";
import { ReactComponent as TwitterLogo } from "../../assets/twitter.svg";
import { ReactComponent as InstragramLogo } from "../../assets/instagram.svg";

export function Footer() {
  return (
    <>
      <footer id="about" className={styles.footer}>
        <div className={styles.footer_container}>
          <div className={styles.info}>
            <NGQLogo className={styles.logo} />
            <p>
              NGQ is an advance booking platform for the users of 21st century.
              Book appointments for doctors, salons & more.
            </p>
            <div className={styles.social_links}>
              <a
                href="https://www.instagram.com/ngqapp/"
                target="_blank"
                rel="noreferrer"
              >
                <InstragramLogo className={styles.social_media_logo} />
              </a>
              {/* <a
                href="https://www.facebook.com/ngqapp"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookLogo className={styles.social_media_logo} />
              </a> */}
              <a
                href="https://twitter.com/ngqapp"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterLogo className={styles.social_media_logo} />
              </a>
              <a
                href="https://linkedin.com/company/ngq"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinLogo className={styles.social_media_logo} />
              </a>
            </div>
          </div>
          <div className={styles.links}>
            <div className={styles.about_links}>
              <h2>About</h2>
              {/* <a href="/">Blogs</a> */}
              <a href="/privacy-policy.html">Privacy Policy</a>
              <a href="/terms-and-conditions.html">Terms of use</a>
            </div>
            <div className={styles.download_links}>
              <h2>Download our apps</h2>
              <a href="https://play.google.com/store/apps/details?id=com.queuewe.ngq">
                <div className={styles.app_download}>
                  <NGQLogo className={styles.logo} />
                  <span>NGQ App</span>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.queuewe.ngqcrew">
                <div className={styles.app_download}>
                  <CREWLogo className={styles.logo} />
                  <span>NGQ CREW App</span>
                </div>
              </a>
            </div>
          </div>
        </div>

        <p className={styles.copyright}>
          © {new Date().getFullYear()} NGQ. All rights reserved.
        </p>
      </footer>
    </>
  );
}
