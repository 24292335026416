import styles from "./App.module.css";

import { NavBar } from "./pages/Navbar/Navbar";
import { Home } from "./pages/Home/Home";
import { Services } from "./pages/Services/Services";
import { Footer } from "./pages/Footer/Footer";
import { DiscountCard } from "./pages/DiscountCard/DiscountCard";
import { AckModal, ContactUsModal /*JoinUs*/ } from "./pages/JoinUs/JoinUs";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

function App() {
  return (
    <div className={styles.app}>
      <Router history={history}>
        <Switch>
          <Route path="/">
            <NavBar />
            <Home />
            <Services />
            <DiscountCard />
            {/* <JoinUs /> */}
          </Route>
        </Switch>

        <Route
          path="/ContactUs"
          component={() => <ContactUsModal history={history} />}
        />
        <Route path="/Ack" component={() => <AckModal history={history} />} />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
