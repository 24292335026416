import { FormEvent, useRef } from "react";
import styles from "./ContactUs.module.css";
import { ReactComponent as CREWLogo } from "../../assets/crew.svg";
import { ReactComponent as PlayStore } from "../../assets/play_store.svg";

interface ContactUsProps {
  onDone: () => void;
}

export function ContactUs(props: ContactUsProps) {
  const nameField = useRef<HTMLInputElement>(null);
  const phoneNumberField = useRef<HTMLInputElement>(null);
  const serviceTypeField = useRef<HTMLSelectElement>(null);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const name = nameField.current?.value!;
    const phoneNumber = phoneNumberField.current?.value!;
    const serviceType = serviceTypeField.current?.value!;

    try {
      await fetch("https://api.ngq.app/json/go/join-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name.trim(),
          phone_number: phoneNumber.trim(),
          service_type: serviceType,
        }),
      });
    } finally {
      props.onDone();
    }
  }

  return (
    <div className={styles.contact_us}>
      <CREWLogo className={styles.crew_logo} />
      <div className={styles.contact_us_form}>
        <h1>Become a Crew</h1>
        <p>Help us with some info, we will contact you</p>
        <form onSubmit={handleSubmit}>
          <select
            ref={serviceTypeField}
            id="service-type-field"
            name="service_type"
            required
          >
            <option value="">Select Service-Type *</option>
            <option value="SALON">Salon</option>
            <option value="HEALTHCARE">Healthcare</option>
            <option value="PLACE">Place</option>
          </select>
          <div className={styles.field}>
            <input
              ref={nameField}
              id="name-field"
              name="name"
              type="text"
              placeholder=" "
              required
            ></input>
            <label id="name-field-label" htmlFor="name-field">
              Name <span aria-hidden="true">*</span>
            </label>
          </div>
          <div className={styles.field}>
            <input
              ref={phoneNumberField}
              id="phone-number-field"
              name="phone_number"
              type="tel"
              placeholder=" "
              required
              pattern="[0-9]+"
              minLength={10}
            ></input>
            <label id="phone-number-field-label" htmlFor="phone-number-field">
              Phone Number <span aria-hidden="true">*</span>
            </label>
          </div>
          <button type="submit">Register</button>
        </form>
      </div>

      <div className={styles.crew_app_info}>
        <CREWLogo className={styles.crew_logo} />
        <div className={styles.mobile_links}>
          <p>Download our app</p>
          <a
            className={styles.google_play_link}
            href="https://play.google.com/store/apps/details?id=com.queuewe.ngq"
          >
            <PlayStore />
          </a>
        </div>
        <h2>OR</h2>
        <div className={styles.web_link}>
          <p>Use our web app to get started now</p>
          <a className={styles.crew_web_app_button} href="https://crew.ngq.app">
            crew.ngq.app
          </a>
        </div>
      </div>
    </div>
  );
}
