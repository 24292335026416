import Modal from "react-modal";
import { ContactUs } from "../ContactUs/ContactUs";
import styles from "./JoinUs.module.css";
import type { History } from "history";
import { Link, useLocation } from "react-router-dom";

export function ContactUsModal(props: { history: History }) {
  const location = useLocation();
  const isOpen = location.pathname === "/ContactUs/";

  return (
    <Modal
      shouldCloseOnEsc={true}
      preventScroll={true}
      isOpen={isOpen}
      onRequestClose={() => props.history.replace("/")}
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(0,0,0, 0.333)",
          width: "100%",
        },
        content: {
          position: "unset",
          inset: "unset",
          border: "unset",
          background: "unset",
          overflow: "unset",
          borderRadius: "unset",
          outline: "unset",
          padding: "unset",
          width: "max-content",
        },
      }}
    >
      <ContactUs onDone={() => props.history.replace("/Ack/")}></ContactUs>
    </Modal>
  );
}

export function AckModal(props: { history: History }) {
  const location = useLocation();
  const isOpen = location.pathname === "/Ack/";

  return (
    <Modal
      shouldCloseOnEsc={true}
      preventScroll={true}
      isOpen={isOpen}
      onRequestClose={() => props.history.replace("/")}
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(0,0,0, 0.333)",
          width: "100%",
        },
        content: {
          position: "unset",
          inset: "unset",
          border: "unset",
          background: "unset",
          overflow: "unset",
          borderRadius: "unset",
          outline: "unset",
          padding: "unset",
          width: "max-content",
        },
      }}
    >
      <div className={styles.ack_container}>
        <h1>Thank you for making request to join NGQ CREW.</h1>
        <p>Our Team will contact you within 24 working hours.</p>
        <button onClick={() => props.history.replace("/")}>Close</button>
      </div>
    </Modal>
  );
}

export function JoinUs() {
  Modal.setAppElement("#root");

  return (
    <div id="join-us" className={styles.join_us}>
      <div className={styles.info}>
        <p>JOIN US</p>
        <h1>Register your Salon, Place, Hospital on NGQ</h1>
      </div>
      <div className={styles.join_us_button}>
        <Link to="/ContactUs/">Register now</Link>
      </div>
    </div>
  );
}
