import styles from "./DiscountCard.module.css";

export function DiscountCard() {
  return (
    <div className={styles.discount_card}>
      <div className={styles.info}>
        <p>BOOK APPOINTMENT</p>
        <h1>Get 30% Discount off on your booking</h1>
        <p>Get discount by booking appointment on NGQ app.</p>
      </div>
      <div className={styles.book_appointment}>
        <a
          className={styles.book_appointment_button}
          href="https://play.google.com/store/apps/details?id=com.queuewe.ngq"
        >
          Book Appointment
        </a>
      </div>
    </div>
  );
}
