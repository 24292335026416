import styles from "./Home.module.css";
import { ReactComponent as PlayStore } from "../../assets/play_store.svg";

import landing_image_webp from "../../assets/landing_image.webp";
import landing_image_png from "../../assets/landing_image.png";

export function Home() {
  return (
    <div id="home" className={styles.container}>
      <div className={styles.info_container}>
        <h1>Perfect tool to get your service on Time</h1>
        <a href="https://play.google.com/store/apps/details?id=com.queuewe.ngq">
          <PlayStore />
        </a>
      </div>
      <picture>
        <source
          srcSet={landing_image_webp}
          type="image/webp"
          width="658"
          height="684"
        />
        <img
          src={landing_image_png}
          alt="NGQ app screenshots"
          width="658"
          height="684"
        />
      </picture>
    </div>
  );
}
