import styles from "./Features.module.css";
import FeatureCard, { Feature } from "./FeatureCard";

export interface FeaturesProps {
  features: Feature[];
}

export default function Features(props: FeaturesProps) {
  return (
    <div className={styles.cardlist}>
      {props.features.map((feature, index) => (
        <FeatureCard
          key={index}
          image={feature.image}
          feature_name={feature.feature_name}
        ></FeatureCard>
      ))}
    </div>
  );
}
